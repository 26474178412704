<template>
    <FinalForm ref="externalLinksForm" :initial-values="links" :submit="save" @change.self="handleFormChange">
        <template v-slot="props">
            <form>
                <div class="flex flex-col flex-1 h-full px-2">
                    <div class="section-title text-base font-frank font-600 block mb-2">
                        External Links
                    </div>
                    <div class="bg-gray-100 p-4 mb-6 relative" v-for="(customLink, index) in links.customLinks" :key="index">
                        <div class="form-row">
                            <TextField :name="`customLinks[${index}].display`" label="display name" :validate="required"/>
                            <TextField :name="`customLinks[${index}].url`" label="url" :validate="[required, safeUrl]"/>
                        </div>
                        <icon name="closeCircle" @click="removeCustomLink(index)" class="w-4 h-4 text-gray-600 absolute top-1 right-1 cursor-pointer" />
                    </div>
                </div>
                <a href="#" class="text-link mb-2 items-center float-right"  @click.prevent="addCustomLink">
                    <icon name="plus" class="h-4 w-4 inline mr-2"></icon> add custom external link
                </a>
                <modal-footer :disable-primary="isProcessing" :primary="props.handleSubmit"></modal-footer>
            </form>
        </template>
    </FinalForm>
</template>

<script>
    import {resolveUrl} from "@/utils/String";
    import {mapActions, mapGetters} from "vuex";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import NotifyMixin from "@/mixins/NotifyMixin";
    import Icon from "@/components/ui/Icon";
    import TextField from "@/components/form/TextField";
    import {FinalForm} from "vue-final-form";
    import ValidatorMixin from "@/components/form/ValidatorMixin";

    export default {
        components: { ModalFooter, TextField, FinalForm, Icon },
        mixins: [ ModalNavigation, NotifyMixin, ValidatorMixin ],
        data: () => {
            return {
                website: {},
                links: {
                    customLinks: [],
                },
                isProcessing: false,
                customLinkModel: {
                  display: null,
                  url: null
                }
            }
        },
        computed: {
            ...mapGetters({
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
        },
        methods: {
            ...mapActions({
                updateWebsiteInStore: 'websites/updateWebsite',
                notifySuccess: 'alerts/notifySuccess',
                notifyError: 'alerts/notifyError',
                clearWebsiteInformation: 'website_info/clearWebsiteInformation',
                addWebsiteInfo: 'website_info/addWebsiteInfo',
            }),
            save(values) {
                const payload = {};
                payload.customers_id = this.website.customers_id;
                payload.communities_id = this.website.communities_id;
                payload.id = this.website.id;
                payload.production_url = resolveUrl(this.website.production_url);
                payload.custom_links = values.customLinks.map(customLink => {
                    customLink.url = resolveUrl(customLink.url);
                    return customLink;
                });

                this.isProcessing = true;

                this.$cmsDataProvider.update('updateWebsite', { data: payload, id: this.website.id })
                    .then(() => {
                        this.isProcessing = false;
                        this.notifySuccess('The external links were updated successfully');
                        this.redirect('websites.show', this.website.id);
                    }).catch(() => {
                    this.isProcessing = false;
                    this.notifyError('There was an error saving the information');
                });
                this.ReloadWebsiteInfo();
            },
            async ReloadWebsiteInfo() {
                this.clearWebsiteInformation();
                await this.$cmsDataProvider.get('websitebyId', {websiteId: this.$route.params.id})
                .then((response) => {
                    if(!response.message) {
                        this.website = response
                        this.addWebsiteInfo(response);
                    }else{
                        this.notifyError('Website not found');
                    }
                }).catch(() => {
                    this.notifyError('There was an error getting the website ');
                    this.website = null
                });
            },
            addCustomLink() {
                this.$refs.externalLinksForm.finalForm.change("customLinks", [
                    ...this.links.customLinks,
                    structuredClone(this.customLinkModel)
                ]);
            },
            removeCustomLink(index) {
                this.$refs.externalLinksForm.finalForm.change("customLinks", [
                    ...this.links.customLinks.slice(0, index),
                    ...this.links.customLinks.slice(index + 1),
                ]);
            },
            handleFormChange({values}) {
                this.links = values;
            },
            loadWebsiteData() {
                this.website = this.getWebsiteInfo
                this.links.customLinks = this.website.custom_links;
            }
        },
        created() {
            this.loadWebsiteData();
        },
    }
</script>
